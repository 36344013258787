<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <div>
        <b-row>
          <b-col cols="12">
            <ValidationProvider vid="name" name="Full Name" rules="required|email" v-slot="{ errors }">
              <div class="form-group">
                <label for="name">Email</label>
                <input :disabled="disabled" type="text" v-model="user.email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                      id="email" aria-describedby="emailHelp" placeholder="Enter your email">
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
          </b-col>
          <b-col cols="12">
            <ValidationProvider vid="document" name="Document" rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="document">Documento </label>
                <input :disabled="disabled"  type="text" v-model="user.document" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                      id="document" aria-describedby="documentHelp" placeholder="Enter the document">
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
          </b-col>
          <b-col cols="12">
            <div v-if="userIsCreateInBD && uidEmpty">
              <b-row>
                <b-col cols="12" lg="6">
                  <ValidationProvider vid="password" name="Password" rules="required|confirmed:confirm" v-slot="{ errors }">
                    <div class="form-group">
                      <label for="password">Password</label>
                      <input type="password" v-model="user.password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                            id="password" placeholder="Password">
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="6">
                  <ValidationProvider vid="confirm" name="Password Confirm" rules="required" v-slot="{ errors }">
                    <div class="form-group">
                      <label for="passwordConfirm">Password</label>
                      <input type="password" v-model="user.passwordConfirm" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                            id="passwordConfirm" placeholder="Password Confirm">
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col v-if="showStatus">
            <loader-process :class="'textSm'" :message="status"></loader-process>
          </b-col>
          <b-col cols="12">
            <div class="d-inline-block w-100">
              <div class="custom-control custom-checkbox d-inline-block">
                <input type="checkbox" class="custom-control-input" :id="formType">
                <label class="custom-control-label" :for="formType">Yo acepto <a href="#">Terminos y Condiciones</a></label>
              </div>
              <button v-if="userIsCreateInBD" type="submit" class="btn btn-primary float-right mx-2">Guardar</button>
              <button v-if="userIsCreateInBD" @click.prevent="cancelVerify" class="btn btn-secondary float-right">Cancelar</button>
              <button v-else @click.prevent="verifyCredentials" class="btn btn-primary float-sm-left float-md-right">Crear Acceso</button>
            </div>
            <!-- MENSAJES EN ALERTAS DISMISSIBLES  -->
            <div class="mt-2">
              <b-alert
                :show="dismissCountDown"
                dismissible
                variant="secondary"
              >
                {{message}}
              </b-alert>
            </div>
            <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">
                  Ya cuentas con un acceso registrado ?
                  <router-link to="/auth/sign-in1" class="iq-waves-effect pr-4">
                      Ingresar
                    </router-link>
                </span>
            </div>
          </b-col>
          </b-row>
        </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { core } from '../../../../config/pluginInit'
import { db, firebaseApp, firebaseApp2 } from '@/config/firebase'
import { mapGetters } from 'vuex'
import LoaderProcess from '@/components/core/loader/LoaderProcess.vue'

export default {
  name: 'SignUp1Form',
  components: {
    LoaderProcess
  },
  props: ['formType'],
  computed: {
    ...mapGetters({
      users: 'Setting/usersState'
    })
  },
  mounted () {
    core.index()
  },
  data: () => ({
    user: {
      email: 'JURIDICA@GRUPOCOLVIVA.COM',
      document: '1274858022'
    },
    userDefault: {
      email: '',
      document: ''
    },
    uidEmpty: false,
    disabled: false,
    docRefOwner: '',
    status: 'Verificando información',
    showStatus: false,
    message: '',
    dismissCountDown: false,
    userIsCreateInBD: false
  }),
  methods: {
    async onSubmit () {
      this.status = 'Verificando y creando el usuario en el sistema'
      this.showStatus = true
      console.log(this.$refs.observer)
      const valid = await (this.$refs.observer.validate())
      let userAuth = ''
      if (valid) {
        let successRegister = false
        // Creo el usuario en auth, si ya esta vamos a lineas 163
        await firebaseApp.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
          .then((user) => {
            console.log('Email: ', this.user.email, 'pass: ', this.user.password)
            userAuth = user
            successRegister = true
            core.showSnackbar('success', 'Se ha creado el usuario, ahora puede usar el login como método de autenticación.')
          })
          .catch((err) => {
            successRegister = false
            console.log('Email: ', this.user.email, 'pass: ', this.user.password, 'err:', err)
            if (err.code === 'auth/email-already-in-use') {
              core.showSnackbar('error', 'Email ya registrado, por favor inicie sesión.')
            } else {
              core.showSnackbar('error', 'Reporte el siguiente error: ' + err)
            }
          }).finally(() => {
          })
        // Como ya esta el usuario, vamos a loguearlo y vamos a obtener el userAuth
        if (!successRegister) {
          await firebaseApp2.auth().signInWithEmailAndPassword(this.user.email, this.user.password)
            .then((user) => {
              userAuth = user
              successRegister = true
            })
            .catch((error) => {
              core.showSnackbar('error', 'El usuario ya esta creado en nuestro sistema de autenticacion, las contraseñas que ingreso deben coincider con las que ya habia ingresado.')
              successRegister = false
              console.log('Error: ', error)
            })
          await firebaseApp2.auth()
            .signOut()
        }
        if (successRegister) {
          db.runTransaction(async transaction => {
            const docsRef = []
            await db.collection('owner')
              .where('mail', '==', this.user.email.toLowerCase())
              .get()
              .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                  docsRef.push(doc.id)
                })
              })
            for (let i = 0; i < docsRef.length; i++) {
              const owner = await db.collection('owner').doc(docsRef[i])
              transaction.update(owner, { uid: userAuth.user.uid })
            }
          })
            .then(() => {
              console.log('transaccion lista')
            }).catch(() => {
              console.log('error')
            })
          await firebaseApp.auth()
            .signOut()
            .then(() => {
              this.$router.push({ name: 'auth1.sign-in1' }).catch(() => {
                console.log()
              })
            })
            .catch((error) => {
              core.showSnackbar('error', 'Reporte el siguiente error: ' + error)
            })
            .finally(() => {
              this.status = ''
              this.showStatus = false
            })
        } else {
          this.status = ''
          this.showStatus = false
        }
      } else {
        core.showSnackbar('error', 'Complete el formulario')
      }
    },
    async verifyCredentials () {
      this.dismissCountDown = false
      this.status = 'Verificando información'
      this.showStatus = true
      console.log('¡doc del user', this.user.document, ' email: ', this.user.email.toLowerCase())
      await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/')
        .where('email', '==', this.user.email.toLowerCase())
        .where('identificationObject.number', '==', this.user.document)
        .get()
        .then(querySnapshot => {
          console.log('QuerySnapshot', querySnapshot)
          if (!querySnapshot.empty) {
            console.log(querySnapshot)
            this.userIsCreateInBD = true
            this.disabled = true
            core.showSnackbar('success', 'El usuario tiene una cuanta validada, por favor cree una contraseña.')
          } else {
            this.message = 'El correo e identifiación ingresado no se encuentra relacionado en nuestra base datos.'
            this.dismissCountDown = true
            core.showSnackbar('success', 'No podemos encontrar un correo relacionado con alguna de las copropiedades registradas.')
          }
          querySnapshot.forEach(doc => {
            console.log('documento', doc.data().uid)
            if (doc.data().uid === undefined) {
              this.uidEmpty = true
            } else {
              this.userIsCreateInBD = false
              this.dismissCountDown = true
              this.message = 'Para este correo ya fue creada una contraseña, por favor inicie sesion'
              this.disabled = false
              this.uidEmpty = false
            }
            this.docRefOwner = doc.id
          })
        })
        .catch((error) => {
          console.log('Error de firebase: ' + error)
        })
        .finally(() => {
          this.status = ''
          this.showStatus = false
        })
    },
    async cancelVerify () {
      this.userIsCreateInBD = !this.userIsCreateInBD
      this.user = Object.assign({}, this.userDefault)
      this.disabled = false
      await this.$refs.observer.reset()
    }
  }
}
</script>

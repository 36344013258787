<template>
  <!-- loader Start -->
  <div>
        <div class="text" :class="textSm">
          <h5>{{message.toUpperCase()}}</h5>
        </div>
        <div class="loader"> <span>SISTEVOT</span></div>
  </div>
  <!-- loader END -->
</template>
<script>
export default {
  name: 'LoaderProcess',
  props: {
    message: { type: String, default: 'CARGANDO' },
    dark: { type: Boolean, default: false },
    textSm: { type: String, default: '' }
  },
  watch: {

  },
  computed: {
  },
  mounted () {
  }
}
</script>

<style scoped>
.text {
    display: flex;
}
.text h5{
    margin: 0 auto;
    font-size: 17px;
    margin-bottom: 13px;
    font-weight: 700;
    letter-spacing: 0.2rem;
}

.textSm h5{
    margin: 0 auto;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 0.15rem;
}

.loader {
    position: inherit;
    border: 7px solid #ffffff;
    border-radius: 50%;
    border-top: 7px solid #ff6d0e;
    border-bottom: 7px solid #0c2160;
    /* border-top: 16px solid blue; */
    /* border-right: 16px solid green; */
    /* border-bottom: 16px solid red; */
    width: 40px;
    height: 40px;
    -webkit-animation: spin-data-v-67c02360 4s linear infinite;
    animation: spin-data-v-67c02360 4s linear infinite;
    margin-bottom: 30px;
}
.loader span{
    position: absolute;
    margin: 1px -18px;

}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<template>
  <div>
    <h1 class="mb-0 mt-0">Registrar</h1>
    <p>Ingrese su email y su documento de identidad registrado con anterioridad en Clase Alpha SAS </p>
        <sign-up1-form formType="firebase"></sign-up1-form>
  </div>
</template>
<script>
import SignUp1Form from './Forms/SignUp1Form'

export default {
  name: 'SignUp1',
  components: { SignUp1Form },
  data: () => ({}),
  methods: {
  }
}
</script>
